// header-toggle styles
.header-toggle {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 0.4s;
    user-select: none;
    width: rem(60px);
    height: rem(60px);

    &.active {
        transform: rotate(45deg);

        .line {
            
            &.top {
                stroke-dashoffset: -98px;
            }

            &.bottom {
                stroke-dashoffset: -138px;
            }
        }
    }

    .line {
        fill:none;
        transition: stroke-dasharray 0.4s, stroke-dashoffset 0.4s;
        stroke: $white;
        stroke-width: 5.5;
        stroke-linecap: round;

        &.top {
            stroke-dasharray: 40 139;
        }

        &.bottom {
            stroke-dasharray: 40 180;
        }
    }
}