.list {
    &-reset {
        list-style-type: none;
        padding: 0;

        &.has-links {
            li {
                margin-bottom: 1em;
            }
        }
    }
}