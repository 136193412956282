@keyframes fade-page {
    0%{
        opacity: 0;
    }
    100%{
		opacity: 1;
	}
}

html,
body{
    height: 100%;
    width: 100%;
    max-width: 100vw;
}

body{
    color: $body-color;
    font: {
        family: map-get($fonts, common);
        size: rem(14px);
        variant-numeric: lining-nums;
        weight: $normal;
    };
    letter-spacing: 0.02em;
    line-height: rem(25px);
    position: relative;

    @include media-breakpoint-up(md) {
        font-size: rem(16px);
        line-height: 1.45;
    }
}

.app{
    animation: fade-page 0.5s ease-in-out forwards;
    background: {
        image: url('/images/bg.jpg');
        size: cover;
        attachment: fixed;
        repeat: no-repeat;
    };
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @include  IE-Edge-only(){
        overflow: hidden;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin: 0 auto $grid-gutter-width;
        max-width: map-get($container-max-widths, xs);
        width: 100%;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            max-width: map-get($container-max-widths, lg);
            margin: 15vh auto;
            padding-top: $app-container-pt-lg;
        }

        &.is-membership-page {
            @include media-breakpoint-up(xl) {
                max-width: map-get($container-max-widths, lg) + 300px;
            }
        }
    }

    &__content {
        background-color: $bg-content-white;
        padding: rem(40px 20px);

        @include media-breakpoint-up(lg) {
            padding: rem(50px);
        }
    }

    &__header {
        flex-shrink: 0;

        @include media-breakpoint-up(lg) {
            margin-right: $grid-gutter-width;
            min-width: rem(300px);
        }
    }
}

main{
    flex-grow: 1;
}