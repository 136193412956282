//Bootstrap grid variables
$grid-gutter-width:         40px;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    small-desktop: 1200px,
    xl: 1440px
);

$container-max-widths: (
    xs: calc(100vw - #{$grid-gutter-width}),
    lg: 1000px
);

//Custom variables
$bg-content-white: rgba($white, 0.87);
$bg-white: rgba($white, 0.8);

$box-shadow: 0 0 30px 14px rgba($black, 0.06);
$border-radius: rem(8px);

$logo-max-height-lg: 80px;
$app-container-pt-lg: $logo-max-height-lg + 60px;

$z-index: (
    nav-xs: 100,
    nav-toggle-xs: 101,
    skip-to-main: 1000
);