::selection {
    background: rgba($primary-blue, 0.4);
    color: $black;
}

a{
    color: inherit;

    &.sm{
        font-size: rem(14px);
    }

    &[href^="tel"]{
        white-space: nowrap;
    }

    &[href^="mailto"]{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &[target="_blank"] {
        background: {
            image: url('/images/external-link.svg');
            repeat: no-repeat;
            position: right;
            size: 0.8em;
        };
        padding-right: 1.25em;
    }
}

h1,
h2,
h3,
h4,
h5,
h6{
    font: {
        family: map-get($fonts, heading);
        weight: $normal;
    };
    line-height: 1.125em;
    margin-top: 0;
}


h1,
.h1{
    font-size: rem(24px);
    text-transform: uppercase;

    @include media-breakpoint-up(md){
        font-size: rem(30px);
    }
}

h2,
.h2{
    font: {
        size: rem(20px);
        weight: $bold;
    };

    @include media-breakpoint-up(lg){
        font-size: rem(24px);
    }
}

h3,
.h3{
    color: $blue-mid;
    font-size: rem(18px);

    @include media-breakpoint-up(md){
        font-size: rem(22px);
    }
}

h4,
.h4{
    font-size: rem(14px);
    line-height: rem(24px);
    text-transform: uppercase;
}


blockquote{
    font: {
        family: $text-common;
        size: rem(20px);
    };
    line-height: 1.5rem;
    margin: 2rem 0;
    padding-left: 2rem;
}

button {
    appearance: none;
    background: transparent;
    border: none;
    cursor: pointer;

    &[disabled]{
        cursor: not-allowed;
    }
}


iframe{
    max-width: 100%;
}

table {
    table-layout: fixed;
    width: 100%;
  
    thead {
        background-color: $primary-blue;

        th {
            font: {
                family: $text-common;
                size: rem(20px);
            };
            line-height: 1.5rem;
            padding: rem(14px 30px);
            text-align: left;
        }
    }

    tbody {
        th {
            vertical-align: top;
            font: {
                size: rem(10px);
            }
        }
        tr {
            border-top: 1px solid $lighter_grey;
        }
        td {
            padding: rem(0px 8px);
            vertical-align: top;
            font: {
                size: rem(9px);
            }
        }
        ul {
            text-align: left;
            list-style-position: outside;
            position: relative;
            right: 30px;
        }
    }
}

*,*::before,*::after {
    box-sizing: border-box
}