.mb{
    &-0{
        margin-bottom: 0;
    }

    &-sm{
        margin-bottom: $grid-gutter-width/3;
    }

    &-base{
        margin-bottom: $grid-gutter-width/2;
    }

    &-gutter{
        margin-bottom: $grid-gutter-width;
    }

    &-md{
        margin-bottom: rem(56px);

        @include media-breakpoint-up(lg){
            margin-bottom: rem(80px);
        }
    }
}

.mt{
    &-0{
        margin-top: 0;
    }

    &-xs{
        margin-top: $grid-gutter-width/4;
    }

    &-sm{
        margin-top: $grid-gutter-width/3;
    }

    &-base{
        margin-top: $grid-gutter-width/2;
    }

    &-gutter{
        margin-top: $grid-gutter-width;
    }

    &-md{
        margin-top: rem(56px);

        @include media-breakpoint-up(lg){
            margin-top: rem(80px);
        }
    }
}

.my{
    &-0{
       margin: {
           top: 0;
           bottom: 0;
       }; 
    }

    &-base {
        margin: {
            top: $grid-gutter-width/2;
            bottom: $grid-gutter-width/2;
        }; 
    }
}

.mx{
    &-0{
       margin: {
           left: 0;
           right: 0;
       }; 
    }

    &-base{
        margin: {
            left: $grid-gutter-width/2;
            right: $grid-gutter-width/2;
        }; 
    }
}

.mr{
    &-base{
        margin-right: $grid-gutter-width/2;
    }

    &-gutter{
        margin-right: $grid-gutter-width;
    }
}

.ml{
    &-base{
        margin-left: $grid-gutter-width/2;
    }

    &-gutter{
        margin-left: $grid-gutter-width;
    }
}

.mb-section{
    margin-bottom: rem(48px);

    @include media-breakpoint-up(lg){
        margin-bottom: rem(120px);
    }
}
