@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

//fonts
$print-font-size:     	12px;

$normal: 400;
$bold: 700;

$text-common:('Helvetica Neue', Helvetica, Arial, sans-serif);
$text-heading:('Lato', sans-serif);

$fonts:(
	common: $text-common,
	heading: $text-heading
);