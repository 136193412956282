.absolute-cover-image{
    object-fit: cover;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
}

/* IE10+ FIX */
@include IE-Edge-only() {
     //object fit doesn't work on IE
    .img-ie-fix{
        max-height: 300px;
    }
}

.icon{
    display: inline-block;
    flex-shrink: 0;
    max-width: 100%;
    
    @include IE-Edge-only(){
        width: 100%;
    }

    &.xs{
        max-width: rem(12px);
        max-height: rem(12px);
    }

    &.sm{
        max-width: rem(14px);
        max-height: rem(14px);
    }

    &.md{
        max-width: 1.5rem;
        max-height: 1.5rem;
    }

    &.lg{
        max-width: 2.5rem;
        max-height: 2.5rem;
    }

    &.xl{
        max-width: 3.5rem;
        max-height: 3.5rem;
    }

    &-up {
        transform: rotate(0deg);
    }
     
    &-right {
        transform: rotate(90deg);
    }
     
    &-down {
        transform: rotate(180deg);
    }
     
    &-left {
        transform: rotate(-90deg);
    }
}

.svg-icon {
    color: inherit;
    fill: none;
    stroke: currentColor;
}
 
.svg-fill {
    fill: currentColor;
    stroke: none;
}


