main {
    a {
        color: $blue-dark;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    .membership {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
        }

        &__media {
            margin-bottom: $grid-gutter-width;

            @include media-breakpoint-up(lg) {
                order: 2;
                max-width: 60%;
            }

            &-legend {
                @include media-breakpoint-up(lg) {
                    float: right;
                }

                > li {
                    font: {
                        size: rem(12px);
                    };
                    position: relative;
                    padding-left: 2.25em;

                    @include media-breakpoint-up(lg) {
                        font: {
                            size: rem(14px);
                        };
                    }

                    &:before {
                        content: '';
                        background-color: $blue-map;
                        border-radius: 50%;
                        display: block;
                        width: 1.5em;
                        height: 1.5em;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    &:last-child {
                        margin-top: 0.6em;

                        @include media-breakpoint-up(lg) {
                            margin-top: 1em;
                        }

                        &:before {
                            background-color: $dark-grey;
                        }
                    }
                }
            }
        }

        &__menu {
            a {
                color: $black;
                line-height: 1.75;
            }
        }
    }

    .observers {
        .logoImage {            
            max-height: 150px;
            height: 100%;
            width: auto;            
            margin-top: 30px!important;
            margin-bottom: 10px;            
        }
    }
}