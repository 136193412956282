div.content-strategy {
	p.accordion-title {
		margin-top: 0;
		margin-bottom: 0.9375rem!important; // 15px;
		font-size: 1.3125rem; // 21px;
		line-height: 1.4375rem; // 23px;
		font-family: "Lato", sans-serif!important;
		&.-active {
			color: $blue-mid;
		}

		span {
			cursor: pointer;
		}
	}

	div.accordion-content {
		margin-top: 2.8125rem; // 45px;
		display: none;
		&.-is-expanded {
			display: block;

		}
	}	

	/* - body : image - */
	#strategyImage {	
		cursor: pointer;
		transition: 0.3s;
		&:hover {
			opacity: 0.7;
		}
	} 

	/* - modal : wrapper - */
	#imgModal { 
		letter-spacing: 0.02em;
		line-height: 1.5625rem;
	}
	
	/* - modal : background - */
	.modal {
		display: none;
		position: fixed;
		z-index: 1;
		padding-top: 100px;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;	
		background-color: rgb(0,0,0);
		background-color: rgba(0,0,0,0.9);

		@media only screen and (max-width: 1023px){
			padding-top: 170px;
		}
	}

	/* - modal : image - */
	.modal-content {
		margin: auto;
		display: block;
		width: 100%;
		max-width: 900px;
		animation-duration: 0.6s;

		@media only screen and (max-width: 1023px){
			width: 90%;		
		}
	}  

	/* - modal : close button- */
	.close {
		position: absolute;
		top: 15px;
		right: 35px;
		color: #fff;
		font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
		font-size: 40px;
		font-weight: bold;
		transition: 0.3s;	
		&:hover,
		&:focus {
			color: #bbb;
			text-decoration: none;
			cursor: pointer;
		}

		@media only screen and (max-width: 1023px){
			top: 120px;
			right: 25px;
		}
	}
}


