// header styles
.header {
    position: relative;

    @include media-breakpoint-up(lg) {
        position: sticky;
        top: 2em;
    }

    &__container {

        @include media-breakpoint-down(md){
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $grid-gutter-width/2 0;
        }
    }

    &__logo {
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: 0;
            top: -$app-container-pt-lg;
        }

        img {
            max-width: rem(200px);
            position: relative;
            z-index: map-get($z-index, nav-toggle-xs );

            @include media-breakpoint-up(lg) {
                max-height: $logo-max-height-lg;
                max-width: none;
            }
        }
    }

    &__toggle {
        display: inline-flex;
        align-items: center;
        width: rem(80px);
        height: rem(80px);
        z-index: map-get($z-index, nav-toggle-xs );

        @include media-breakpoint-up(lg){
            display: none;
        }
    }

    &__nav {
        position: relative;
        z-index: map-get($z-index, nav-toggle-xs );

        &-wrap {
            
            @include media-breakpoint-down(md){
                background-color: $blue-mid;
                width: 100vw;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
                position: fixed;
                top: 0;
                left: 0;
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
                z-index: -1;

                &.open {
                    opacity: 1;
                    pointer-events: all;
                    right: 0;
                    z-index: map-get($z-index, nav-xs );
        
                    li {
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
        }
    }
}