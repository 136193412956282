// header-waves styles
@keyframes animate{
	0%{
		background-position-x: 0;
	}
	100%{
		background-position-x: 1000px;
	}
}
@keyframes animate2{
	0%{
		background-position-x: 0;
	}
	100%{
		background-position-x: -1000px;
	}
}

.waves {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: {
        image: url('/images/wave.png');
        size:1000px 100px;
    };

    &:nth-child(1) {
        animation: animate 30s linear infinite;
        animation-delay:0s;
        bottom: 0;
        opacity:1;
        z-index:1000;
    }

    &:nth-child(2) {
        animation: animate2 15s linear infinite;
        animation-delay:-5s;
        bottom: 10px;
        opacity:0.5;
        z-index:999;
    }

    &:nth-child(3) {
        animation:animate 30s linear infinite;
        animation-delay:-2s;
        bottom: 15px;
        opacity:0.2;
        z-index:998;
    }

    &:nth-child(4) {
        animation:animate2 5s linear infinite;
        animation-delay:-5s;
        bottom: 20px;
        opacity:0.7;
        z-index:997;
    }

    &__wrap {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow:hidden; 

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}
