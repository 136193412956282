@import './_functions/_contrast';

//brand palette
$primary-blue: #6FC6E8;
$blue: #6FC6E8;
$blue-mid: #0080a9;
$blue-dark: #185e9f;
$blue-map: #3C5267;

$white: #FFFFFF;
$black: #000000;

$lighter-grey: #f3f4f6;
$light-grey: #E6E7E8;
$grey: #767777;
$dark-grey: #3D3832;

$body-color: $black;

//Contrast
$dark-color: $body-color;
$light-color: $white;




