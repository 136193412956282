// menu styles
.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    > li {
        margin-bottom: $grid-gutter-width/4;
        text-align: center;

        a {
            color: $white;
            display: inline-block;
            font: {
                family: $text-heading;
                size: rem(20px);
            };
            letter-spacing: 0.015em;
            padding: 0.25em 0;
            position: relative;
            text: {
                decoration: none;
                transform: uppercase;
            };
            transition: background 0.3s ease;

            &[target="_blank"] {
                padding-right: 1em;
                @include media-breakpoint-up(lg) {
                    background-position: 60%;
                    padding-right: 0;
                }
            }

            @media screen and (max-height: 400px) {
                color: $black
            }

            @include media-breakpoint-up(lg) {
                background-color: $bg-white;
                color: $black;
                display: block;
                font: {
                    size: rem(16px);
                };
                padding: rem(20px 30px);
                text-align: left;;
            }

            &:after {
                content: '';
                background-color: $white;
                display: block;
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 3px;
                bottom: 0;
                left: 0;
                transform-origin: bottom right;
                transition: transform 0.25s ease-out;

                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            &:hover,
            &:focus,
            &.active {
                &:after {
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }

                @include media-breakpoint-up(lg) {
                    background-color: $white;
                }
            }

            &.active {
                font-weight: $bold;
                
                span {
                    @include media-breakpoint-up(lg) {
                        border-left: 4px solid $blue-mid;
                    }
                }
            }

            span {
                padding: 5px;
                padding-left: 10px;

                @include media-breakpoint-up(lg) {
                    border-left: 2px solid $blue-mid;
                }
            }
        }
    }
}