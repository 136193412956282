// skip-to-main styles
.skip-to-main{
    background-color: $black;
    box-shadow: $box-shadow;
    color: $white;
    border-radius: 0 0 $border-radius 0;
    padding: rem(24px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: map-get($z-index, skip-to-main );

    &:hover,
    &:focus{
        width: auto;
        height: auto;
        clip: auto;
        min-width: 40vw;
    }
}
